import { createContext, useContext, useReducer, useEffect } from 'react';
import location from '../data/location.json';
import { cloneObject } from '../helpers';

const LocationStateContext = createContext();
const LocationDispatchContext = createContext();

const initialState = {
  code: '',
  name: '...',
  case: '...',
  coordinates: [47.222078, 39.720358],
  phone: '...',
  phoneURI: '...',
  zoom: 13,
  isDefault: true,
  isLoaded: false
};

const LocationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOCATION':
      return {
        ...initialState,
        ...action.payload
      };

    default: {
      return state;
    }
  }
};

export const LocationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LocationReducer, cloneObject(initialState));

  useEffect(() => {
    if (!localStorage.getItem('location')) localStorage.setItem('location', 'rnd');

    dispatch({
      type: 'SET_LOCATION',
      payload: {
        ...location.filter((v) => v.code === localStorage.getItem('location'))[0],
        isLoaded: true
      }
    });
  }, []);

  return (
    <LocationStateContext.Provider value={state}>
      <LocationDispatchContext.Provider value={dispatch}>
        {children}
      </LocationDispatchContext.Provider>
    </LocationStateContext.Provider>
  );
};

export const useLocationState = () => {
  const context = useContext(LocationStateContext);

  if (context === undefined) {
    throw new Error('useLocationState must be used within a LocationProvider');
  }

  return context;
};

export const useLocationDispatch = () => {
  const context = useContext(LocationDispatchContext);

  if (context === undefined) {
    throw new Error('useLocationDispatch must be used within a LocationProvider');
  }

  return context;
};

export const useLocationProvider = () => {
  return [useLocationState(), useLocationDispatch()];
};
