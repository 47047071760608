import { createContext, useContext, useReducer } from 'react';
import { cloneObject } from '../helpers';

const MortgageStateContext = createContext();
const MortgageDispatchContext = createContext();

const initialState = { objectPrice: 1000000, initialFee: 300000, mortgageDuration: 5 };

const MortgageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MORTGAGE':
      return {
        ...state,
        ...action.payload
      };

    default: {
      return state;
    }
  }
};

export const MortgageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MortgageReducer, cloneObject(initialState));

  return (
    <MortgageStateContext.Provider value={state}>
      <MortgageDispatchContext.Provider value={dispatch}>
        {children}
      </MortgageDispatchContext.Provider>
    </MortgageStateContext.Provider>
  );
};

export const useMortgageState = () => {
  const context = useContext(MortgageStateContext);

  if (context === undefined) {
    throw new Error('useMortgageState must be used within a MortgageProvider');
  }

  return context;
};

export const useMortgageDispatch = () => {
  const context = useContext(MortgageDispatchContext);

  if (context === undefined) {
    throw new Error('useMortgageDispatch must be used within a MortgageProvider');
  }

  return context;
};

export const useMortgageProvider = () => {
  return [useMortgageState(), useMortgageDispatch()];
};
