import { createContext, useContext, useReducer } from 'react';
import { useRouter } from 'next/router';
import { cloneObject } from '../helpers';

const ObjectsStateContext = createContext();
const ObjectsDispatchContext = createContext();

const initialState = {
  filter: {
    type: 'продажа',
    category: 'квартира',
    rooms: [],
    studio: 0,
    priceFrom: '',
    priceTo: '',
    squareFrom: '',
    squareTo: '',
    squareLivingFrom: '',
    squareLivingTo: '',
    squareKitchenFrom: '',
    squareKitchenTo: '',
    squareAreaFrom: '',
    squareAreaTo: '',
    floorFrom: '',
    floorTo: '',
    floorsFrom: '',
    floorsTo: '',
    ceiling: '',
    stock: [],
    balcony: [],
    renovation: [],
    buildingType: [],
    bathroom: [],
    lotType: [],
    subCategory: [],
    entrance: [],
    commercialType: [],
    commercialBuildingType: [],
    mikroraion: [],
    noCommission: 0,
    hersonCert: 0,
    exclusive: 0,
    ads: []
  },
  sorting: {
    sort: 'last-update.date',
    sortDirection: ''
  },
  pagination: {
    page: 1,
    length: 8
  },
  map: {
    searchZone: [],
    zoomLevel: 13,
    bbox: []
  },
  state: {
    isFiltersMobileVisible: false,
    isMapCatalogVisible: false
  }
};

const ObjectsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTER':
      return { ...state, filter: { ...initialState.filter, ...action.payload } };

    case 'SET_SORTING':
      return { ...state, sorting: { ...initialState.sorting, ...action.payload } };

    case 'SET_PAGINATION':
      return { ...state, pagination: { ...initialState.pagination, ...action.payload } };

    case 'SET_MAP':
      return { ...state, map: { ...initialState.map, ...action.payload } };

    case 'SET_STATE':
      return { ...state, state: { ...initialState.state, ...action.payload } };

    default: {
      return state;
    }
  }
};

export const ObjectsProvider = ({ children }) => {
  const { pathname, query } = useRouter();
  const [state, dispatch] = useReducer(ObjectsReducer, cloneObject(initialState), (state) => {
    if (!pathname.startsWith('/objects')) return state;

    const type = query.path ? query.path.match(/buy|rent/g) : null;
    const category = query.path ? query.path.match(/flats|rooms|houses|sites|commercial/g) : null;
    const rooms = query.path ? query.path.match(/studio|1k|2k|3k|4k/g) : null;

    /** TYPE */
    if (type) {
      switch (type[0]) {
        default:
        case 'buy':
          state.filter.type = 'продажа';
          break;

        case 'rent':
          state.filter.type = 'аренда';
          break;
      }
    } else if (query.type) {
      state.filter.type = query.type;
    }
    /** CATEGORY */
    if (category) {
      switch (category[0]) {
        default:
        case 'flats':
          state.filter.category = 'квартира';
          break;

        case 'rooms':
          state.filter.category = 'комната';
          break;

        case 'houses':
          state.filter.category = 'дом';
          break;

        case 'sites':
          state.filter.category = 'участок';
          break;

        case 'commercial':
          state.filter.category = 'коммерция';
          break;
      }
    } else if (query.category) {
      state.filter.category = query.category;
    }
    /** ROOMS */
    if (category && category[0] === 'flats' && rooms) {
      switch (rooms[0]) {
        case 'studio':
          state.filter.studio = 1;
          break;

        case '1k':
          state.filter.rooms = ['1'];
          break;

        case '2k':
          state.filter.rooms = ['2'];
          break;

        case '3k':
          state.filter.rooms = ['3'];
          break;

        case '4k':
          state.filter.rooms = ['4'];
          break;

        default:
          break;
      }
    } else if (query.studio || query.rooms) {
      if (query.rooms) state.filter.rooms = JSON.parse(query.rooms);
      if (query.studio) state.filter.studio = 1;
    }
    /** PRICE */
    if (query.priceFrom) state.filter.priceFrom = query.priceFrom;
    if (query.priceTo) state.filter.priceTo = query.priceTo;
    /** SQUARE */
    if (query.squareFrom) state.filter.squareFrom = query.squareFrom;
    if (query.squareTo) state.filter.squareTo = query.squareTo;
    /** AREA SQUARE */
    if (query.squareAreaFrom) state.filter.squareAreaFrom = query.squareAreaFrom;
    if (query.squareAreaTo) state.filter.squareAreaTo = query.squareAreaTo;
    /** DISTRICT */
    if (query.mikroraion) state.filter.mikroraion = JSON.parse(query.mikroraion);
    /** PROMOS */
    if (query.noCommission) state.filter.noCommission = 1;
    if (query.hersonCert) state.filter.hersonCert = 1;
    if (query.exclusive) state.filter.exclusive = 1;
    /** COMMERCIAL TYPE */
    if (query.commercialType) state.filter.commercialType = JSON.parse(query.commercialType);
    /** LIVING SQUARE */
    if (query.squareLivingFrom) state.filter.squareLivingFrom = query.squareLivingFrom;
    if (query.squareLivingTo) state.filter.squareLivingTo = query.squareLivingTo;
    /** KITCHEN SQUARE */
    if (query.squareKitchenFrom) state.filter.squareKitchenFrom = query.squareKitchenFrom;
    if (query.squareKitchenTo) state.filter.squareKitchenTo = query.squareKitchenTo;
    /** FLOOR */
    if (query.floorFrom) state.filter.floorFrom = query.floorFrom;
    if (query.floorTo) state.filter.floorTo = query.floorTo;
    /** FLOORS */
    if (query.floorsFrom) state.filter.floorsFrom = query.floorsFrom;
    if (query.floorsTo) state.filter.floorsTo = query.floorsTo;
    /** CEILING */
    if (query.ceiling) state.filter.ceiling = query.ceiling;
    /** BALCONY */
    if (query.balcony) state.filter.balcony = JSON.parse(query.balcony);
    /** RENOVATION */
    if (query.renovation) state.filter.renovation = JSON.parse(query.renovation);
    /** BATHROOM */
    if (query.bathroom) state.filter.bathroom = JSON.parse(query.bathroom);
    /** BUILDING TYPE */
    if (query.buildingType) state.filter.buildingType = JSON.parse(query.buildingType);
    /** STOCK TYPE */
    if (query.stock) state.filter.stock = JSON.parse(query.stock);
    /** LOT TYPE */
    if (query.lotType) state.filter.lotType = JSON.parse(query.lotType);
    /** SUB CATEGORY */
    if (query.subCategory) state.filter.subCategory = JSON.parse(query.subCategory);
    /** ENTRANCE TYPE */
    if (query.entrance) state.filter.entrance = JSON.parse(query.entrance);
    /** COMMERCIAL BUILDING TYPE */
    if (query.commercialBuildingType)
      state.filter.commercialBuildingType = JSON.parse(query.commercialBuildingType);
    /** PAGINATION */
    if (query.page) state.pagination.page = Number(query.page);
    /** SORTING */
    if (query.sort) state.sorting.sort = query.sort;
    if (query.sortDirection) state.sorting.sortDirection = query.sortDirection;
    /** MAP */
    if (query.searchZone) state.map.searchZone = JSON.parse(query.searchZone);
    if (query.zoomLevel) state.map.zoomLevel = Number(query.zoomLevel);

    return state;
  });

  return (
    <ObjectsStateContext.Provider value={state}>
      <ObjectsDispatchContext.Provider value={dispatch}>{children}</ObjectsDispatchContext.Provider>
    </ObjectsStateContext.Provider>
  );
};

export const useObjectsState = () => {
  const context = useContext(ObjectsStateContext);

  if (context === undefined) {
    throw new Error('useObjectsState must be used within a ObjectsProvider');
  }

  return context;
};

export const useObjectsDispatch = () => {
  const context = useContext(ObjectsDispatchContext);

  if (context === undefined) {
    throw new Error('useObjectsDispatch must be used within a ObjectsProvider');
  }

  return context;
};

export const useObjectsProvider = () => {
  return [useObjectsState(), useObjectsDispatch()];
};
