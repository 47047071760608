import Head from 'next/head';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { AppProvider } from '../context/app';
import { LocationProvider } from '../context/location';
import { ObjectsProvider } from '../context/objects';
import { BuildingsProvider } from '../context/buildings';
import { MortgageProvider } from '../context/mortgage';
import Metrics from '../components/Metrics';
import useWindowDimensions from '../hooks/useWindowDimensions';
import '../styles/globals.scss';

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const { height } = useWindowDimensions();

  useEffect(
    () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`),
    [height]
  );
  useEffect(() => document.body.removeAttribute('style'), [router.pathname]);

  return (
    <AppProvider>
      <LocationProvider>
        <ObjectsProvider>
          <BuildingsProvider>
            <MortgageProvider>
              <Head>
                <meta name="application-name" content="ЛЕНДЛОРД" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                <meta name="apple-mobile-web-app-title" content="ЛЕНДЛОРД" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="theme-color" content="#FFFFFF" />
                <meta name="author" content="ch3rn1k" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="apple-touch-icon" sizes="180x180" href="/images/icons/ios/180.png" />
                <link rel="icon" href="/favicon.ico" />
                <link rel="icon" type="image/png" sizes="32x32" href="/images/icons/ios/32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/images/icons/ios/16.png" />
                <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                      '@context': 'https://schema.org',
                      '@type': 'Organization',
                      url: 'https://landlord.ru',
                      logo: 'https://landlord.ru/images/logo-lord.jpg'
                    })
                  }}
                />
              </Head>
              <Component {...pageProps} />
              <Metrics />
            </MortgageProvider>
          </BuildingsProvider>
        </ObjectsProvider>
      </LocationProvider>
    </AppProvider>
  );
};

export default App;
